'use client'

import { useEffect } from 'react'
import { buttonVariants } from './ui/button'
import { H3 } from './ui/heading'
import { Fit, Layout, useRive } from '@rive-app/react-canvas-lite'
import Link from 'next/link'
import { usePostHog } from 'posthog-js/react'

function Missing() {
  const { RiveComponent } = useRive({
    src: '/animations/404_v2.riv',
    autoplay: true,

    layout: new Layout({ fit: Fit.Contain, minX: 300, minY: 300 }),
  })

  const event = usePostHog()
  useEffect(() => {
    event.capture('not_found')
  }, [event])

  return (
    <div className="container">
      <div className="flex flex-col items-center justify-center py-20 h-screen/2">
        <div className="grid gap-6">
          <RiveComponent className="h-[300px] w-[300px] my-auto" />
          <header>
            <H3 className="text-center">404</H3>
            <p className="text-center">We could not find page you are looking for.</p>
          </header>
          <Link
            href="/"
            className={buttonVariants({ variant: 'primary', rounded: true })}
          >
            Return Home
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Missing
